import ArrowDisabler from './utils/ArrowDisabler.Glide.js';
//import 'alpinejs';
import Plyr from 'plyr';

// App main
const main = async () => {
    const lazysizes = await import(/* webpackChunkName: "lazysizes" */'lazysizes');

    return null;
};

const glideLoader = async () => {
    const glideImport = await import(/* webpackChunkName: "glide" */'@glidejs/glide/dist/glide.modular.esm');
    return glideImport;
}


// Execute async function
main().then( (root) => {
    window.loadSliders();

    const players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
    }));

    let images = document.querySelectorAll('img.lazyload');
    for (let index = 0; index < images.length; index++) {
        const element = images[index];
        element.addEventListener('lazyloaded', function(){
            if (this.previousSibling && this.previousSibling.classList.contains('placeholder')) {
                this.previousSibling.classList.add('opacity-0');
            }
        })
    }
});

// LOAD sliders
window.loadSliders = async () => {
    const glidesOnPage = document.querySelectorAll('.glide:not(.glide--slider)');

    if(glidesOnPage.length > 0) {
        glideLoader().then( GlideModule => {
            const Glide = GlideModule.default;
            const {Controls, Swipe, Autoplay, Breakpoints} = GlideModule;

            const imageSlider = document.querySelectorAll('.glide-images:not(.glide--slider)');
            imageSlider.forEach(function(elm) {
                new Glide(elm, {
                    type: 'carousel',
                    perView: 1,
                    rewind: false,
                    gap: 0,
                }).mount({ Controls, Swipe, Breakpoints, ArrowDisabler});
            })
        });
    }
}


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
